import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCart as addToCartQuery } from './Cart/queries/addToCart.gql';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { useHistory } from 'react-router';
import { useNotification } from '@jetshop/core/components/Notifications';
import { Query } from 'react-apollo';
import ProductAddedToCartQuery from './ProductPage/AddToCart/ProductAddedToCartQuery.gql';
import AddToCartToast from './ProductPage/AddToCart/ProductToast';
import Spinner from './ui/Spinner';

// THIS FILE IS FOR EXPOSING FLIGHT FUNCTIONS AND STATE TO FINDIFY.
// NORMALLY YOU SHOULD NOT NEED TO EDIT THIS FILE

let callbackOptions = {};

export const useFlightFunctions = () => {
  useEffect(() => {
    init();
    return cleanUp;
  });

  // ADD TO CART
  const { cartId, setCartId } = useContext(CartIdContext);
  const [trigger] = useNotification();

  const onAddToCartSuccess = callbackOptions => {
    trigger(
      <Query
        query={ProductAddedToCartQuery}
        variables={{ articleNumber: callbackOptions.articleNumber, id: null }}
      >
        {({ loading, error, data }) => {
          if (loading) return <Spinner />;
          if (error) {
            if (error.graphQLErrors && error.graphQLErrors.length > 0) {
              error.graphQLErrors.forEach(gqError => {
                console.error(
                  gqError.extensions?.code + ': ',
                  gqError.extensions?.data?.argument
                );
              });
            }
            return null;
          }
          // SUCCESS
          return (
            <AddToCartToast
              product={data?.product}
              addedArticleNumber={callbackOptions.articleNumber}
            />
          );
        }}
      </Query>
    );
  };

  const [add] = useMutation(addToCartQuery, {
    onCompleted: data => {
      addToCartSuccess({
        onAddToCartSuccess,
        callbackOptions,
        cartId,
        setCartId
      })({ data });
    }
  });

  const addToCart = articleNumber => {
    callbackOptions.articleNumber = articleNumber;
    add({
      variables: {
        input: {
          cartId,
          articleNumber: articleNumber,
          quantity: 1
        }
      }
    });
  };

  // // FAVORITES
  const { toggle, inList } = useProductList();
  console.log(inList);

  const toggleFavourite = (mainArticleNumber, variantArticleNumber) => {
    return toggle(mainArticleNumber, {
      variantArticleNumber: variantArticleNumber || undefined,
      productName: undefined
    });
  };

  const isFavourite = (mainArticleNumber, variantArticleNumber) => {
    return inList(mainArticleNumber, {
      variantArticleNumber: variantArticleNumber || undefined,
      productName: undefined
    });
  };

  // FINDIFY INTERNAL FLIGHT LINKING
  const history = useHistory();
  const goToRoute = route => {
    // FOOLPROOF FIX FOR BAD URLS FROM HYPERDRIVE
    if (route.match('https')) {
      console.warn('Absolute url detected: ', route);
      route = route.replace('https://', '');
      route = route.substr(route.indexOf('/'));
    }
    history.push(route);
  };

  // EXPOSE METHODS FOR FINDIFY
  const init = () => {
    window.flightFunctions = {};
    window.flightFunctions.addToCart = addToCart;
    window.flightFunctions.toggleFavourite = toggleFavourite;
    window.flightFunctions.isFavourite = isFavourite;
    window.flightFunctions.goToRoute = goToRoute;
  };

  const cleanUp = () => {
    window.flightFunctions = null;
  };
};
